import { CSSProperties } from 'react'
import { match } from 'ts-pattern'
import styles from './ProfileBadge.module.scss'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'

export type BadgeType = 'verified' | 'public-figure' | 'edited-profile'

export default function ProfileBadge({
  type,
  className = '',
  style = {},
  bottomTooltip,
  rightTooltip,
}: {
  type: BadgeType
  className?: string
  style?: CSSProperties
  bottomTooltip?: boolean
  rightTooltip?: boolean
}) {
  let tooltipDirection = ''
  if (bottomTooltip) {
    tooltipDirection = 'bottom-tooltip'
  } else if (rightTooltip) {
    tooltipDirection = 'right-tooltip'
  }
  return (
    <div
      className={`${styles.profileBadge} profile-badge ${className}`}
      style={style}
    >
      <div className={`CE tooltip ${tooltipDirection}`}>
        {match(type)
          .with('public-figure', () => (
            <>
              <span>Public Figure</span>
              <Icon icon={Icons.Info} />
              <div className="tooltip-content">
                Public Figures are predicted personalities for famous people,
                such as politicians, entertainers and business leaders.
              </div>
            </>
          ))
          .with('edited-profile', () => (
            <>
              <span>Edited Profile</span>
              <Icon icon={Icons.Info} />
              <div className="tooltip-content">
                This is a predicted profile, which you then edited by overriding
                the personality type to a custom one based on your knowledge of
                the person.
              </div>
            </>
          ))
          .with('verified', () => (
            <>
              <Icon icon={Icons.Verified} />
              <div className="tooltip-content">
                This is a verified profile, which is created and owned by a
                Crystal user.
              </div>
            </>
          ))
          .exhaustive()}
      </div>
    </div>
  )
}
