import React, { CSSProperties } from 'react'
import styles from './LoadingBar.module.scss'

export type Props = {
  width: string
  className?: string
  style?: CSSProperties
}

export default function LoadingBar({ width, className, style }: Props) {
  return (
    <div
      className={`${styles.loadingBar} ${className}`}
      style={{ ...style, width: width }}
    />
  )
}
